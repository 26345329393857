import React from 'react';
import PropTypes from 'prop-types';
import Layout from './Layout';
import Classes from './Menubar.module.css';
import Sidebar from './Sidebar';

const Menubar = props => {
  return (
    <div className={Classes.Header}>
      <section>
        <header>
          <nav>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/publications">Publications</a>
              </li>
              <li>
                <a
                  href="https://rany.elhousieny.com/Resume.pdf"
                  target="_blank"
                >
                  Resume
                </a>
              </li>
            </ul>
          </nav>
        </header>
      </section>
    </div>
  );
};

Menubar.propTypes = {};

export default Menubar;
