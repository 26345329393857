import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import Scroll from './Scroll';

import avatar from '../assets/images/avatar.png';
import config from '../../config';
import { Link } from 'gatsby';
import Header from './Header/header';
import { slide as Menu } from 'react-burger-menu';

export class Sidebar extends Component {
  constructor(props) {
    super(props);

    switch (this.props.showTabs) {
      case 'false':
        this.state = {
          tabs: [{ content: 'Rany ElHousieny', href: '/' }],
          showTabs: true,
          avatarImage: avatar,
        };
        break;

      case 'resume':
        this.state = {
          tabs: [
            { content: 'About', href: 'about' },
            { content: 'Experience', href: 'experience' },
            { content: 'Education', href: 'education' },
            { content: 'Certificates', href: 'skills' },
            { content: 'skills', href: 'comps' },
            { content: 'Interests', href: 'interests' },
            { content: 'Awards', href: 'awards' },
            { content: 'Publications', href: 'publications' },
          ],
          showTabs: true,
          avatarImage: avatar,
        };
        break;
      case 'publications':
        this.state = {
          tabs: [
            { content: 'Publications', href: 'publcations' },
            { content: 'Frontend', href: 'frontend' },
          ],
          showTabs: true,
        };
        break;
      case 'microfrontends':
        this.state = {
          tabs: [
            { content: 'Rany ElHousieny', href: 'microfrontends' },
            { content: 'Definition', href: 'definition' },
            { content: 'Implementation', href: 'implementation' },
          ],
          showTabs: true,
        };
        break;

      default:
        this.state = {
          tabs: [
            { content: 'Rany ElHousieny', href: 'https://rany.elhousieny.com' },
          ],
          showTabs: true,
          avatarImage: avatar,
        };
    }
  }

  render() {
    let { tabs } = this.state;

    return (
      <nav
        className="navbar navbar-expand-md navbar-dark bg-primary fixed-top"
        id="sideNav"
      >
        <a className="navbar-brand" href="#page-top">
          <span className="d-block d-lg-none">Menu</span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile rounded-circle mx-auto mb-2"
              src={avatar}
              alt="Rany ElHousieny"
            />
          </span>
        </a>

        <dev
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <Menu right className="Header__menu">
            <Link to="/" className="Header__menu__item menuTitle">
              Menu
            </Link>
            <hr />
            {tabs.map((tab, i) => {
              const { href, content } = tab;
              return (
                <li className="nav-item" key={href}>
                  <Link className="nav-link Header__menu__item" to={`#${href}`}>
                    {content}
                  </Link>
                </li>
              );
            })}
          </Menu>
        </dev>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Scrollspy
            items={tabs.map(s => s.href)}
            currentClassName="active"
            offset={-300}
            className="navbar-nav"
          >
            {tabs.map((tab, i) => {
              const { href, content } = tab;
              return (
                <li className="nav-item" key={href}>
                  <Scroll type="id" element={href}>
                    <Link className="nav-link" to={`#${href}`}>
                      {content}
                    </Link>
                  </Scroll>
                </li>
              );
            })}
          </Scrollspy>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
