import React from 'react';
import '../assets/sass/publications/navigation.scss';

const Navigation = () => {
  return (
    <div className="btn">
      <div className="btn__burger"></div>
    </div>
  );
};

export default Navigation;
