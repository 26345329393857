import React from 'react';

import Layout from '../components/Layout';
import awscert from '../assets/images/awscert.png';
import azurecert from '../assets/images/Azure_cert.png';
import azurebadge from '../assets/images/Azure_badge.png';
import awsbadgesaa from '../assets/images/aws_saa_badge.png';
import apfmaward from '../assets/images/apfmaward.png';
import apfmlogo from '../assets/images/apfm_logo.png';
import apfmfulllogo from '../assets/images/apfm_full_logo.png';
import truphy from '../assets/images/truphy.png';
import goldstar from '../assets/images/goldstar.png';
import goldstarlogo from '../assets/images/goldstar_logo.png';
import mslogo from '../assets/images/ms_logo.png';
import epsilon from '../assets/images/epe.png';
import epelogo from '../assets/images/epe_logo.png';
import gatsbylogo from '../assets/images/gatsby_logo.png';
import gatsbylogoblack from '../assets/images/gatsby_logo_black.png';
import reactlogo from '../assets/images/react_logo.png';
//import gitlablogo from '../assets/images/gitlab_logo.png';
import nsulogo from '../assets/images/nsu_logo.png';
import calyptus from '../assets/images/calyptus_logo.png';
import nsusecurity from '../assets/images/NSU_Security.png';
import negotiation from '../assets/images/advanced_negotiation.png';
import master from '../assets/images/master.png';
import liverpool from '../assets/images/liverpool_logo.png';
import seahawks from '../assets/images/seahawks.png';
import soccer from '../assets/images/soccer.png';
import marvel from '../assets/images/Marvel.png';
import flash from '../assets/images/flash.png';
import awslogo from '../assets/images/aws_logo.png';
import graphqllogo from '../assets/images/graphql_logo.png';
import appsynclogo from '../assets/images/appsync_logo.png';
import eslogo from '../assets/images/elasticsearch_logo.png';
import csslogo from '../assets/images/css3_logo.png';
import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';
import Menubar from '../components/Menubar';
import Classes from './index.module.css';
import upelogo from '../assets/images/upe_logo.png';
import htmllogo from '../assets/images/html_logo.png';
import sasslogo from '../assets/images/sass_logo.png';
import dynamodb from '../assets/images/dynamodb_logo.png';
import mongodb from '../assets/images/mongodb_logo.png';
import cordova from '../assets/images/cordova.png';
import mongodb_basics from '../assets/images/mongodb_basics.png';
import mongodb_university from '../assets/images/mongodb_university.png';
import maven_logo from '../assets/images/maven_logo.png';
import zulily_logo from '../assets/images/zulily_logo.png';
import dropwizard_logo from '../assets/images/dropwizard_logo.png';
import psm1 from '../assets/images/psmi.png';
import psm1_certificate from '../assets/images/PSM_Certificate.png';
import master_GPA from '../assets/images/master_GPA.jpg';
import EDUREKA_AWS_Architect from '../assets/images/EDUREKA_AWS_Architect.png';
import EDUREKA_MS_cert from '../assets/images/EDUREKA_MS_cert.png';
import edureka_logo from '../assets/images/edureka_logo.jpg';
import edureka_react_cer from '../assets/images/Edureka_React_Cert.png';
import Navigation from '../components/Navigation';
import Nav from './publications.module.css';
import MFE_logo from '../assets/images/MicroFrontends Logo 2.png';
import Vancery_logo from '../assets/images/Vancery_Logo.png';

const IndexPage = () => (
  <Layout content="Rany ElHsouieny" title="Rany ElHousieny">
    <Sidebar showTabs="resume" />

    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <div>
            <nav>
              <div className={Nav.Row}>
                <ul className={Nav.MainNav}>
                  <li className="nav-item">
                    <Link to="/microfrontends">
                      <span style={{ color: 'black' }}> Micro-Frontends</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link style={{ color: 'black' }} to="/publications">
                      Publications
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <p />
          <div className="d-flex">
            <h1 className="mb-0">
              {config.firstName}
              <span className="text-primary">{config.lastName}</span>
              {/* <span style={{ marginBottom: 0, fontSize: 30 }}>, phd</span>
            <span style={{ marginBottom: 0, fontSize: 12 }}>(ABD)</span> */}
            </h1>
            <span
              style={{
                fontSize: 25,
                color: '#212529',
                marginTop: 50,
                fontWeight: 'bold',
                letterSpacing: 0,
                fontFamily: 'Saira Extra Condensed',
              }}
            >
              , Ph.D
            </span>
            <span
              style={{
                fontSize: 12,
                color: '#17a2b8',
                marginTop: 63,
                fontWeight: 'bold',
                fontFamily: 'Saira Extra Condensed',
              }}
            >
              (ABD)
            </span>
          </div>
          <h3 className="subheading">
            SENIOR <span style={{ color: 'red' }}>MANAGER</span> SOFTWARE
            ENGINEER
          </h3>

          <div className="subheading mb-5">
            {config.address} · {config.phone} ·
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
            <a
              className={Classes.FocusLinked}
              href="https://www.youracclaim.com/badges/6985c632-a7c1-4dc7-8f6e-4e4ef11013a6/public_url"
              target="_blank"
            >
              AWS Solutions Architect Certified
            </a>{' '}
            <a
              className={Classes.FocusLinked}
              href="https://rany.tk/static/awscert.png"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={awsbadgesaa}
                alt=""
                height="30"
                width="30"
              />
            </a>
            {' , '}
            <a
              className={Classes.FocusLinked}
              href="https://www.edureka.co/lms/certificate/e7a2ee6756f34f20322d7ea8d32146ff"
              target="_blank"
            >
              <span style={{ color: 'red' }}>Microservices </span> Solutions
              Architect Certified
            </a>{' '}
            {' , '}
            <a
              className={Classes.FocusLinked}
              href="https://www.youracclaim.com/badges/19602876-6d4d-40bf-a254-467aaa346513/public_url"
              target="_blank"
            >
              Azure Certified
            </a>{' '}
            <a
              className={Classes.FocusLinked}
              href="https://rany.tk/static/Azure_cert.png"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={azurebadge}
                alt=""
                height="30"
                width="30"
              />
            </a>
            {' , '}
            <a
              className={Classes.FocusLinked}
              href="https://rany.tk/static/PSMI.pdf"
              target="_blank"
            >
              Professional Scrum Master Certified
            </a>{' '}
            <a
              className={Classes.FocusLinked}
              href="https://api.badgr.io/public/assertions/VPy3zwAKQOKDmUKeOmU4VA?identity__email=ranyel%40msn.com"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={psm1}
                alt=""
                height="40"
                width="40"
              />
            </a>
            {' , '}
            {'  '}
            <a
              className={Classes.FocusLinked}
              href="https://www.edureka.co/lms/certificate/a138826b28a43b8af3907d3d2316e4b0"
              target="_blank"
            >
              Certified React Front-End Developer
            </a>
            <a
              className={Classes.FocusLinked}
              href="https://www.edureka.co/lms/certificate/a138826b28a43b8af3907d3d2316e4b0"
              target="_blank"
            ></a>
            {'  '}
            <i className="fab fa-react" />
            {' , '}
            {'  '}
            <Link className={Classes.FocusLinked} to="/microfrontends">
              <span style={{ color: 'red' }}>Micro-Frontends </span> Architect
            </Link>
            {' , '}
            Commercial software development manager offering
            <span style={{ fontWeight: 'bold', color: 'red' }}>
              {' '}
              25+ years{' '}
            </span>
            managing cross-functional, global development teams. Developed
            underperforming teams to have the ability to ship complex product
            releases significantly ahead of schedule.
            <span className={Classes.Focus}>
              {' '}
              Designed and implemented{' '}
            </span>{' '}
            products used by
            <span className={Classes.Focus}> 500M+ </span>
            users Cross Platforms.
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={goldstarlogo}
              alt=""
              height="30"
              width="30"
            />
            <a
              className={Classes.FocusLinked}
              href="https://rany.tk/static/goldstar-903d5dc81f6e748e390869ecf2a99eac.png"
              target="_blank"
            >
              Gold Star Award
            </a>{' '}
            Winner presented from VP of Microsoft for saving failing projects,
            moving them{' '}
            <span className={Classes.Focus}> from failed to shipped</span> with
            high quality, and{' '}
            <span className={Classes.Focus}>
              {' '}
              Increasing products deployment speed by 70%
            </span>
            . Known and awarded for
            <span>
              {' '}
              <a
                href="https://www.linkedin.com/feed/update/urn:li:activity:6763522663341285376/"
                target="_blank"
              >
                building high-performing teams.
              </a>{' '}
            </span>
            <a
              className={Classes.FocusLinked}
              href="https://rany.tk/static/apfmaward-190e4d6eaa15283f84472cf20db1cfd2.png"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={truphy}
                alt=""
                height="20"
                width="20"
              />
              Awarded
            </a>{' '}
            from the BOD of APFM for the high impact in the first 3 months
            (Increased efficiency and profit by{' '}
            <span style={{ fontWeight: 'bold', color: 'red' }}>$3.98M</span>.)
          </p>
          <div>
            <p>
              <a href="https://rany.elhousieny.com/Resume.pdf" target="_blank">
                Full Resume can be found at
                https://rany.elhousieny.com/Resume.pdf
              </a>
            </p>
          </div>
          <p />
          <a
            href="https://www.youracclaim.com/badges/6985c632-a7c1-4dc7-8f6e-4e4ef11013a6/public_url"
            target="_blank"
          >
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={awsbadgesaa}
              alt=""
              height="70"
              width="70"
            />
          </a>
          <a
            href="https://www.youracclaim.com/badges/19602876-6d4d-40bf-a254-467aaa346513/public_url"
            target="_blank"
          >
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={azurebadge}
              alt=""
              height="70"
              width="70"
            />
          </a>
          <a href={epsilon} target="_blank">
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={epelogo}
              alt="UPE"
              height="80"
              width="80"
            />
          </a>
          <a
            href="https://api.badgr.io/public/assertions/VPy3zwAKQOKDmUKeOmU4VA?identity__email=ranyel%40msn.com"
            target="_blank"
          >
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={psm1}
              alt="PSM"
              height="60"
              width="60"
            />
          </a>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url} target="_blank">
                  <i className={`fab ${icon}`} />
                </a>
              );
            })}
          </div>
          <div>
            <p />
            <i className="fab fa-react" />
            {'  '}
            <i className="fab fa-gitlab" />
            {'  '}
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={gatsbylogoblack}
              alt=""
              height="15"
              width="15"
            />{' '}
            {'   '} Last Update: 2021 Nov 21 12:30 PM PDT
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">SR. SOFTWARE ENGINEERING MANAGER</h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={zulily_logo}
                  alt=""
                  height="50"
                  width="50"
                />{' '}
                Zulily
              </div>
              <p className={Classes.FLoat}>
                Working with an amazing team building systems using MongoDB{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={mongodb}
                  alt=""
                  height="30"
                  width="30"
                />{' '}
                , Elastic Search{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={eslogo}
                  alt=""
                  height="30"
                  width="30"
                />{' '}
                , AWS{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={awslogo}
                  alt=""
                  height="40"
                  width="40"
                />
                , React{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={reactlogo}
                  alt=""
                  height="30"
                  width="30"
                />{' '}
                , CSS3{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={csslogo}
                  alt=""
                  height="20"
                  width="20"
                />{' '}
                , HTML5{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={htmllogo}
                  alt=""
                  height="20"
                  width="20"
                />{' '}
                , Sass{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={sasslogo}
                  alt=""
                  height="40"
                  width="40"
                />{' '}
                , Dropwizard{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={dropwizard_logo}
                  alt=""
                  height="20"
                  width="20"
                />{' '}
                , Java <i className="fab fa-java list-inline-item" /> , ...
                <br />
                <br />
                <ul>
                  <li>Built high-performing self-managed agile dev teams</li>
                  <li>
                    {' '}
                    Shipped two major web apps used in curation and profit
                    analysis that increased efficiency and profit by{' '}
                    <span className={Classes.Focus}>20% ($4M)</span>
                  </li>
                  <li>
                    Created Agile/Scrum process for shipping high quality
                    products in <span className={Classes.Focus}>30% </span> of
                    the time
                  </li>
                  <li>Created Quality and Scalability metrics</li>
                  <li>
                    Reduced cost to Price and Audit an Event by{' '}
                    <span className={Classes.Focus}>
                      80% (9h/wk to 2h/wk) leading to $1.52M in OIBDA
                    </span>
                  </li>
                  <li>
                    Reduced time to create curation story from 45m to 5m leading
                    to <span className={Classes.Focus}>$2.76M in OIBDA</span>
                  </li>

                  <li>
                    Reduced Cost to Curate an Event by{' '}
                    <span className={Classes.Focus}>
                      35% (4h/wk to 2.5h/wk) leading to $0.342M in OIBDA
                    </span>
                  </li>
                  <li>
                    Improved performance, cost, and response time by Moving the
                    systems from{' '}
                    <span className={Classes.Focus}>Monolithic Systems</span>{' '}
                    with <span className={Classes.Focus}>REST APIs</span> to{' '}
                    <span className={Classes.Focus}>
                      {' '}
                      <Link
                        className={Classes.FocusLinked}
                        to="/microfrontends"
                      >
                        Micro-Frontends Architecture
                      </Link>{' '}
                      and AWS Serverless GraphQL
                    </span>{' '}
                    <img
                      className="img-fluid img-profile mx-auto mb-2"
                      src={graphqllogo}
                      alt=""
                      height="20"
                      width="20"
                    />{' '}
                    <span className={Classes.Focus}>Microservices</span> using
                    AWS {''}
                    <span className={Classes.Focus}>AppSync </span>{' '}
                    <img
                      className="img-fluid img-profile mx-auto mb-2"
                      src={appsynclogo}
                      alt=""
                      height="20"
                      width="20"
                    />{' '}
                    <a
                      href="https://www.linkedin.com/pulse/from-rest-serverless-graphqlappsync-progressive-rany-elhousieny/"
                      target="_blank"
                    >
                      {' '}
                      ( Click Here For Details )
                    </a>
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">April 2020 - Present</span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">SR. SOLUTIONS ARCHITECT - Freelance</h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={Vancery_logo}
                  alt=""
                  height="50"
                  width="50"
                />
                {'  '} Vancery
              </div>
              <p>
                Vancery is a place for professionals to collaborate and to
                exchange ideas and perspectives. I am helping Companies to
                Migrate from Monolithic Architectures to{' '}
                <span className={Classes.Focus}>Microservices</span> and{' '}
                <span className={Classes.Focus}>MicroFrontends</span>. Provide
                Solutions Architect and design consultations. <p></p>
                <a
                  href="https://www.vancery.com/profile/rany-elhousieny-131861"
                  target="blank"
                >
                  Profile:
                  https://www.vancery.com/profile/rany-elhousieny-131861
                </a>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2021 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Founder - Freelance</h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={MFE_logo}
                  alt=""
                  height="50"
                  width="50"
                />
                {'  '} MicroFrontends.Info
              </div>
              <p className={Classes.FLoat}>
                I am helping Companies to Migrate from Monolithic Architectures
                to <span className={Classes.Focus}>Microservices</span> and{' '}
                <span className={Classes.Focus}>MicroFrontends</span>. Provide
                Solutions Architect and design consultations.{' '}
              </p>
              <p>
                <a href="https://MicroFrontends.Info" target="blank">
                  Company Page: https://MicroFrontends.Info
                </a>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">April 2021 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">SR. SOFTWARE ENGINEERING MANAGER</h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={apfmfulllogo}
                  alt=""
                  height="50"
                  width="50"
                />
                A Place For Mom
              </div>
              <p className={Classes.FLoat}>
                Designed, Developed, and Managed 8 main cloud distributed{' '}
                <span className={Classes.Focus}>Microservices</span> solutions
                with cross platform mobile apps using Apache Cordova{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={cordova}
                  alt=""
                  height="30"
                  width="30"
                />{' '}
                , GraphQL{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={graphqllogo}
                  alt=""
                  height="20"
                  width="20"
                />{' '}
                , Elastic Search{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={eslogo}
                  alt=""
                  height="30"
                  width="30"
                />{' '}
                , AWS{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={awslogo}
                  alt=""
                  height="40"
                  width="40"
                />
                , React{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={reactlogo}
                  alt=""
                  height="30"
                  width="30"
                />
                , Gatsby{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={gatsbylogo}
                  alt=""
                  height="25"
                  width="25"
                />
                , AWS AppSync{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={appsynclogo}
                  alt=""
                  height="25"
                  width="25"
                />{' '}
                , CSS3{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={csslogo}
                  alt=""
                  height="20"
                  width="20"
                />{' '}
                , HTML5{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={htmllogo}
                  alt=""
                  height="20"
                  width="20"
                />{' '}
                , Sass{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={sasslogo}
                  alt=""
                  height="40"
                  width="40"
                />{' '}
                , DynamoDB{' '}
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={dynamodb}
                  alt=""
                  height="30"
                  width="30"
                />{' '}
                , ...
                <br />
                <br />
                In the first 3 months, I was{' '}
                <a
                  href="https://rany.tk/static/apfmaward-190e4d6eaa15283f84472cf20db1cfd2.png"
                  className={Classes.FocusLinked}
                  target="_blank"
                >
                  awarded
                </a>
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={truphy}
                  alt=""
                  height="20"
                  width="20"
                />{' '}
                from the board of directors for impacting the company positively
                by:
                <ul>
                  <li>
                    Improving performance by{' '}
                    <span className={Classes.Focus}>60%</span> leading to{' '}
                    <span className={Classes.Focus}>40%</span> {''}
                    resource savings
                  </li>
                  <li>
                    Saving over <span className={Classes.Focus}>1400 </span>{' '}
                    hours per month of manual labor{' '}
                    <span className={Classes.Focus}>($3.8M/year) </span> by
                    automating complicated tasks
                  </li>
                  <li>
                    Building high performing teams and provide best practices
                    for the entire company to follow.
                  </li>
                  <li>
                    Improved performance, cost, and response time by Moving the
                    systems from{' '}
                    <span className={Classes.Focus}>Monolithic DB</span> with{' '}
                    <span className={Classes.Focus}>REST APIs</span> to{' '}
                    <span className={Classes.Focus}>
                      AWS Serverless GraphQL
                    </span>{' '}
                    <img
                      className="img-fluid img-profile mx-auto mb-2"
                      src={graphqllogo}
                      alt=""
                      height="20"
                      width="20"
                    />{' '}
                    <span className={Classes.Focus}>Microservices</span> using
                    AWS {''}
                    <span className={Classes.Focus}>AppSync </span>{' '}
                    <img
                      className="img-fluid img-profile mx-auto mb-2"
                      src={appsynclogo}
                      alt=""
                      height="20"
                      width="20"
                    />{' '}
                    <a
                      href="https://www.linkedin.com/pulse/from-rest-serverless-graphqlappsync-progressive-rany-elhousieny/"
                      target="_blank"
                    >
                      {' '}
                      ( Click Here For Details )
                    </a>
                  </li>
                  <img
                    src={apfmaward}
                    alt=""
                    height="300"
                    width="300"
                    className={Classes.FLoat}
                  />
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">October 2018 - April 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">PRINCIPLE SOFTWARE ENGINEERING MANAGER</h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={mslogo}
                  alt=""
                  height="30"
                  width="30"
                />
                Microsoft : Microsoft Azure – Cloud Data, and AI
              </div>
              <p>
                <ul>
                  <li>
                    Azure Unified Endpoint Management (UEM) Microservices:
                    Improved customer experience for managing apple devices
                    enrolled in Apple Deployment Program and managed by
                    Microsoft Intune by 10%.
                  </li>
                  <li>
                    Lead several cross-organizational initiatives within 11k
                    employee organization to improve experiences and
                    satisfaction for 500M+ end-users..{' '}
                  </li>
                  <li>
                    Lead ARM64 development; game changing, all-day battery
                    device with continuous connectivity and new mobile CPU
                    announced on Dec. 2017.{' '}
                  </li>
                  <li>
                    <span className={Classes.Focus}>
                      {' '}
                      Increased NPS (Net Promoter Score) and Customer
                      satisfaction by 15% for 100M+{' '}
                    </span>{' '}
                    enterprise customers by creating a customer satisfaction
                    dashboard and equation measuring by multiple factors.
                  </li>
                  <li>
                    <span className={Classes.Focus}>
                      Reduced customer support calls volume by 24% ($10M)
                    </span>
                    ;{' '}
                    <span className={Classes.Focus}>
                      developed Machine Learning model{' '}
                    </span>
                    identifying user feedback issues to alert engineers of new
                    issues, decreasing resolution time, and saving{' '}
                    <span className={Classes.Focus}>$18k/mo</span> in labor
                    costs.
                  </li>
                  <li>
                    Reduced the MTTR (mean time to resolution) to{' '}
                    <span className={Classes.Focus}>50%</span> which increased
                    adoption to latest release by{' '}
                    <span className={Classes.Focus}>10%</span>.{' '}
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">October 2016 - October 2018</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">SENIOR SOFTWARE ENGINEERING MANAGER</h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={mslogo}
                  alt=""
                  height="30"
                  width="30"
                />
                Microsoft : Microsoft Office for Apple platforms (iOS / OSX){' '}
              </div>
              <p>
                <ul>
                  <li>
                    Released{' '}
                    <a
                      href="https://www.microsoft.com/en-us/microsoft-365/blog/2014/03/27/announcing-the-office-you-love-now-on-the-ipad/"
                      target="_blank"
                    >
                      Office for iPad
                    </a>{' '}
                    , iPhone, and Mac -announced by Satya on March 2014.
                  </li>
                  <li>
                    Designed and implemented features used by{' '}
                    <span className={Classes.Focus}>100M+ users</span>: linking
                    to{' '}
                    <a
                      href="https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-authentication-libraries"
                      target="_blank"
                    >
                      ADAL
                    </a>{' '}
                    (Azure Active Directory Authentication Library) for client
                    authentication with Office365, Single Sign On, Third party
                    Authentication (Box, Dropbox, iCloud), and{' '}
                    <span className={Classes.Focus}>OAuth2</span>
                    {'  '}
                    Authentication.
                  </li>
                  <li>
                    Created a combined engineering transition process pilot used
                    as a blueprint by{' '}
                    <span className={Classes.Focus}>10K+</span> employees.
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">June 2013 - October 2016</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">GROUP MANAGER</h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={mslogo}
                  alt=""
                  height="30"
                  width="30"
                />
                Microsoft : Microsoft Research (MSR)
              </div>
              <p>
                <ul>
                  <li>
                    Managed an org of developers, test, and product managers in
                    MSR (Microsoft Research) to ship products.
                  </li>
                  <li>
                    <span className={Classes.Focus}>Awarded and promoted</span>{' '}
                    for creating a reliable process guaranteeing continuous
                    delivery and optimal customer satisfaction for{' '}
                    <span className={Classes.Focus}>
                      Bing and Office 100M+ customers
                    </span>
                    .
                  </li>
                  <li>
                    In less than a year, developed the org into{' '}
                    <span className={Classes.Focus}>
                      high performers with strong moral
                    </span>{' '}
                    shipping high impact products.
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">June 2010 - June 2013</span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">SENIOR SOFTWARE ENGINEERING MANAGER</h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={mslogo}
                  alt=""
                  height="30"
                  width="30"
                />
                Microsoft : ENTERPRISE NETWORKING, MSN, BING
              </div>
              <p>
                <ul>
                  <li>
                    Increased new product deployment speed{' '}
                    <span className={Classes.Focus}>70%</span> by designing and
                    implementing a development process that combines{' '}
                    <span className={Classes.Focus}>SCRUM</span> and RAD;
                    manages specifications, development, testing, passing
                    quality gates, and deployments in 2 vs 13 months.
                  </li>
                  <li>
                    Built an <span className={Classes.Focus}>org of 30+</span>{' '}
                    from ground up and developed people located in California,
                    India, and China to become high performers; handled
                    interviews, hiring, managing up, and managing low performers
                    out without impacting the business.
                  </li>
                  <li>
                    <img
                      className="img-fluid img-profile mx-auto mb-2"
                      src={goldstarlogo}
                      alt=""
                      height="30"
                      width="30"
                    />
                    <a
                      href="https://rany.tk/static/goldstar-903d5dc81f6e748e390869ecf2a99eac.png"
                      target="_blank"
                    >
                      Gold Star Award
                    </a>{' '}
                    Winner presented from{' '}
                    <span className={Classes.Focus}>
                      VP of Microsoft for saving products from failure and
                      releasing them with high quality in 30% of the time
                    </span>
                    .
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">June 1999 - June 2010</span>
            </div>
          </div>
          <img src={goldstar} alt="" height="300" width="300" />
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">
                <a href="https://rany.tk/static/PHD.JPG" target="_blank">
                  PH.D
                </a>
                <span
                  style={{
                    fontSize: 15,
                    fontStyle: 'none',
                    color: '#17a2b8',
                  }}
                >
                  (ABD)
                </span>
              </h3>

              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={nsulogo}
                  alt=""
                  height="50"
                  width="50"
                />
                <a href="https://www.nova.edu/index.html" target="_blank">
                  Nova Southeastern University, FL, USA
                </a>
              </div>
              <div className={Classes.Focus}>
                Computer and Information Sciences
              </div>
              <p>
                <a
                  className={Classes.FocusLinked}
                  href="https://rany.tk/static/phd_GPA.jpg"
                  target="_blank"
                >
                  GPA: 3.79/4.0
                </a>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">August 2008 - May 2014</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">
                <a href="https://rany.tk/static/Master.pdf" target="_blank">
                  MASTER OF SCIENCE
                </a>
              </h3>
              <div className="subheading mb-3">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={nsulogo}
                  alt=""
                  height="50"
                  width="50"
                />
                <a href="https://www.nova.edu/index.html" target="_blank">
                  Nova Southeastern University, FL, USA
                </a>
              </div>
              <div className={Classes.Focus}>
                Computer and Information Sciences
              </div>

              <p className={Classes.Focus}>
                <a
                  className={Classes.FocusLinked}
                  href="https://rany.tk/static/master_GPA.jpg"
                  target="_blank"
                >
                  GPA: 4.0/4.0
                </a>{' '}
                with{' '}
                <a
                  className={Classes.FocusLinked}
                  href="https://rany.tk/static/EPE.pdf"
                  target="_blank"
                >
                  Honors
                </a>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">August 2004 - May 2008</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        <div className="w-100">
          <h2 className="mb-5">Certificates</h2>

          <span className="d-none d-lg-block">
            <a
              className={Classes.FocusLinked}
              href="https://rany.tk/static/awscert.png"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={awscert}
                alt=""
                height="300"
                width="300"
              />
            </a>
            <a
              className={Classes.FocusLinked}
              href="https://www.youracclaim.com/badges/6985c632-a7c1-4dc7-8f6e-4e4ef11013a6/public_url"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={awsbadgesaa}
                alt=""
                height="50"
                width="50"
              />
            </a>
            <a
              href="https://www.youracclaim.com/badges/6985c632-a7c1-4dc7-8f6e-4e4ef11013a6/public_url"
              target="_blank"
            >
              AWS Solutions Architect Certified{' '}
            </a>
          </span>
          <span className="d-none d-lg-block">
            <a
              href="https://www.edureka.co/lms/certificate/5f059ccdff95449164c4595f71a84c29"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={EDUREKA_AWS_Architect}
                alt=""
                height="305"
                width="305"
              />
            </a>{' '}
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={edureka_logo}
              alt=""
              height="30"
              width="30"
            />{' '}
            <a
              href="https://www.edureka.co/lms/certificate/5f059ccdff95449164c4595f71a84c29"
              target="_blank"
            >
              AWS Solutions Architect{' '}
            </a>
          </span>
          <span className="d-none d-lg-block">
            <a
              href="https://www.edureka.co/lms/certificate/e7a2ee6756f34f20322d7ea8d32146ff"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={EDUREKA_MS_cert}
                alt=""
                height="305"
                width="305"
              />
            </a>{' '}
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={edureka_logo}
              alt=""
              height="30"
              width="30"
            />{' '}
            <a
              href="https://www.edureka.co/lms/certificate/e7a2ee6756f34f20322d7ea8d32146ff"
              target="_blank"
            >
              Microservices Solutions Architect{' '}
            </a>
          </span>
          <span className="d-none d-lg-block">
            <a href="https://rany.tk/static/PSMI.pdf" target="_blank">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={psm1_certificate}
                alt=""
                height="300"
                width="300"
              />
            </a>
            <span>
              <a
                href="https://api.badgr.io/public/assertions/VPy3zwAKQOKDmUKeOmU4VA?identity__email=ranyel%40msn.com"
                target="_blank"
              >
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={psm1}
                  alt=""
                  height="50"
                  width="50"
                />
              </a>
            </span>
            <a href="https://www.scrum.org/user/655750" target="_blank">
              Professional Scrum Master Certified{' '}
            </a>
          </span>

          {/* React Certificate */}
          <span className="d-none d-lg-block">
            <a
              href="https://rany.elhousieny.com/Edureka_React.pdf"
              target="_blank"
            >
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={edureka_react_cer}
                alt=""
                height="300"
                width="300"
              />
            </a>
            <span>
              <i className="fab fa-react" />
            </span>
            <a
              href="https://www.edureka.co/lms/certificate/a138826b28a43b8af3907d3d2316e4b0"
              target="_blank"
            >
              Certified React Front-End Developer
            </a>
            <a
              className={Classes.FocusLinked}
              href="https://www.edureka.co/lms/certificate/a138826b28a43b8af3907d3d2316e4b0"
              target="_blank"
            ></a>
            {'  '}
            <i className="fab fa-react" />
          </span>
          {/* Azure Certificate */}
          <span className="d-none d-lg-block">
            <a href="https://rany.tk/static/Azure_cert.png" target="_blank">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={azurecert}
                alt=""
                height="300"
                width="300"
              />
            </a>
            <a href="https://www.scrum.org/user/655750" target="_blank">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={azurebadge}
                alt=""
                height="50"
                width="50"
              />
            </a>

            <a
              href="https://www.youracclaim.com/badges/19602876-6d4d-40bf-a254-467aaa346513/public_url"
              target="_blank"
            >
              Microsoft Azure Cloud Certified{' '}
            </a>
          </span>

          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={nsusecurity}
              alt=""
              height="300"
              width="300"
            />
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={nsulogo}
              alt=""
              height="50"
              width="50"
            />
            <a href="https://rany.tk/static/NSU_Security.png" target="_blank">
              Administration of Information Security{' '}
            </a>
          </span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={master}
              alt=""
              height="300"
              width="300"
            />
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={nsulogo}
              alt=""
              height="50"
              width="50"
            />
            <a href="https://rany.tk/static/Master.pdf" target="_blank">
              Master in Sciences in Computer and Information Sciences{' '}
            </a>
          </span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={epsilon}
              alt=""
              height="300"
              width="300"
            />
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={epelogo}
              alt=""
              height="50"
              width="50"
            />
            <a href="https://rany.tk/static/EPE.pdf" target="_blank">
              Honors (UPE) in Computing and Information Sciences{' '}
            </a>
          </span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={negotiation}
              alt=""
              height="300"
              width="300"
            />
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={calyptus}
              alt=""
              height="50"
              width="50"
            />
            <a
              href="https://rany.tk/static/AdvancedNegotiation.png"
              target="_blank"
            >
              Advanced Negotiation{' '}
            </a>
          </span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={mongodb_basics}
              alt=""
              height="300"
              width="300"
            />{' '}
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={mongodb_university}
              alt=""
              height="50"
              width="50"
            />
            <a
              href="http://university.mongodb.com/course_completion/1bed9277-b756-482d-b9b5-8d0992cb42a6"
              target="_blank"
            >
              MongoDB{' '}
            </a>
          </span>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="comps"
      >
        <div className="w-100">
          <h2 className="mb-5">CORE COMPETENCIES</h2>

          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-check" />
              Project, People, &amp; Technical Management
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              <a
                href="https://www.youracclaim.com/badges/6985c632-a7c1-4dc7-8f6e-4e4ef11013a6/public_url"
                target="blank"
              >
                Certified Solutions
                <span style={{ fontWeight: 'bold', color: 'red' }}>
                  {' '}
                  Architect{' '}
                </span>
              </a>
              <a
                href="https://www.youracclaim.com/badges/6985c632-a7c1-4dc7-8f6e-4e4ef11013a6/public_url"
                target="blank"
              >
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={awsbadgesaa}
                  alt=""
                  height="20"
                  width="20"
                />
              </a>
              <a
                href="https://www.youracclaim.com/badges/19602876-6d4d-40bf-a254-467aaa346513/public_url"
                target="blank"
              >
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={azurebadge}
                  alt=""
                  height="20"
                  width="20"
                />
              </a>
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              <a href="https://www.edureka.co/lms/certificate/e7a2ee6756f34f20322d7ea8d32146ff">
                Certified
                <span style={{ fontWeight: 'bold', color: 'red' }}>
                  {' '}
                  Microservices{' '}
                </span>
                Solutions Architect
              </a>
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              <a
                href="https://www.edureka.co/lms/certificate/a138826b28a43b8af3907d3d2316e4b0"
                target="_blank"
              >
                Certified React Front-End Developer
              </a>
              <a
                className={Classes.FocusLinked}
                href="https://www.edureka.co/lms/certificate/a138826b28a43b8af3907d3d2316e4b0"
                target="_blank"
              ></a>
              {'  '}
              <i className="fab fa-react" />
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              <Link className={Classes.FocusLinked} to="/microfrontends">
                Micro-Frontends Architect
              </Link>

              {'  '}
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Cross Functional Teams
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Agile Development &amp;{' '}
              <a
                href="https://badgr.com/public/assertions/VPy3zwAKQOKDmUKeOmU4VA?identity__email=ranyel@msn.com"
                target="blank"
              >
                Professional{' '}
                <span style={{ fontWeight: 'bold', color: 'red' }}>Scrum</span>{' '}
                Master Certified
              </a>
              <a href="https://rany.tk/static/PSMI.pdf" target="blank">
                <img
                  className="img-fluid img-profile mx-auto mb-2"
                  src={psm1}
                  alt=""
                  height="20"
                  width="20"
                />
              </a>
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Development, Testing, &amp; Research
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              25 years of Software Engineering Experience
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              18 years Management Experience
            </li>
            <li>
              <i className="fa-li fa fa-check" />8 years Managing Managers
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Leading Through New Product Ambiguity
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              <a
                href="https://www.linkedin.com/posts/ranyelhousieny_forming-storming-norming-and-performing-activity-6633074072207134720-AZOE"
                target="_blank"
              >
                building high-performing teams.
              </a>{' '}
            </li>
          </ul>

          <br />
          <br />

          <h3 className="mb-5">Programming Languages &amp; Tools</h3>
          <ul className="list-inline dev-icons">
            <li className="list-inline-item">
              <i className="fab fa-html5" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-css3-alt" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-angular" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-react" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-js" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-node-js" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-gitlab" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-sass" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-jira" />
            </li>

            <li className="list-inline-item">
              <i className="fab fa-java" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-aws" />
            </li>
            <li className="list-inline-item">
              <i className="fab fa-npm" />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={awsbadgesaa}
                alt=""
                height="50"
                width="50"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={azurebadge}
                alt=""
                height="50"
                width="50"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={dynamodb}
                alt=""
                height="60"
                width="40"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={graphqllogo}
                alt=""
                height="50"
                width="50"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={gatsbylogo}
                alt=""
                height="40"
                width="40"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={appsynclogo}
                alt=""
                height="50"
                width="50"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={eslogo}
                alt=""
                height="50"
                width="50"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={mongodb}
                alt=""
                height="55"
                width="55"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={maven_logo}
                alt=""
                height="55"
                width="55"
              />
            </li>
            <li className="list-inline-item">
              {' '}
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={dropwizard_logo}
                alt=""
                height="30"
                width="30"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={MFE_logo}
                alt=""
                height="50"
                width="50"
              />
            </li>
          </ul>
        </div>
      </section>
      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="interests"
      >
        <div className="w-100">
          <h2 className="mb-5">Interests</h2>
          <p>
            I love coaching, mentoring, system design, web development, learning
            new technologies, public speaking (especially motivational
            speeches), problem solving, and{' '}
            <Link to="/publications">
              publishing technical articles, books, and videos
            </Link>
          </p>
          <p className={Classes.interests}>
            I like soccer
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={soccer}
              alt=""
              height="70"
              width="70"
            />
            and I am a big fan of Liverpool
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={liverpool}
              alt=""
              height="70"
              width="70"
            />
            , in addition to Seahawks ..
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={seahawks}
              alt=""
              height="60"
              width="60"
            />
            football. I like all Marvel
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={marvel}
              alt=""
              height="40"
              width="70"
            />
            characters and only the Flash
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={flash}
              alt=""
              height="60"
              width="60"
            />
            from DC. I really enjoy the ability to have a big impact on people's
            life.
          </p>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="awards"
      >
        <div className="w-100">
          <h2 className="mb-5">Awards</h2>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-trophy text-warning" />
              <a
                href="https://rany.tk/static/apfmaward-190e4d6eaa15283f84472cf20db1cfd2.png"
                target="_blank"
              >
                Stock Awards
              </a>{' '}
              for high impact, improving performance, increasing revenue [A
              Place For Mom] - 2019
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning" />1<sup>st</sup>
              Place - Audience favorite award for Deep Learning Hackathon
              project - [Microsoft] - Hackathon 2018
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning" />1<sup>st</sup>
              Place - Wiki and Technical Documentation Authoring Leader -
              [Microsoft] - 2017
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning" />
              Tech Transfer Awards for shipping new technology products -
              [Microsoft] - 2012
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning" />
              <a href="https://rany.tk/static/EPE.pdf" target="_blank">
                Upsilon Pi Epsilon [Honors]
              </a>{' '}
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={upelogo}
                alt=""
                height="10"
                width="16"
              />
              {'-'}
              <a
                style={{ fontSize: 15 }}
                href="http://upe.acm.org/"
                target="_blank"
              >
                [The Honor Society for The Computing and Information
                Disciplines]
              </a>
              {'- 2008'}
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning" />
              <a
                href="https://rany.tk/static/goldstar-903d5dc81f6e748e390869ecf2a99eac.png"
                target="_blank"
              >
                The Gold Star Award
              </a>{' '}
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={goldstarlogo}
                alt=""
                height="30"
                width="30"
              />{' '}
              from Microsoft VP for high impact - [Microsoft] - 2005
            </li>
          </ul>
          <br />
          <h2 className="mb-5">Awards</h2>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-trophy text-warning" />
              <a
                href="https://rany.tk/static/apfmaward-190e4d6eaa15283f84472cf20db1cfd2.png"
                target="_blank"
              >
                Stock Awards
              </a>{' '}
              for high impact, improving performance, increasing revenue [A
              Place For Mom] - 2019
            </li>
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={apfmlogo}
              alt=""
              height="50"
              width="50"
            />
            <img src={apfmaward} alt="" height="200" width="200" />
            <li>
              <i className="fa-li fa fa-trophy text-warning" />1<sup>st</sup>
              Place - Audience favorite award for Deep Learning Hackathon
              project - [Microsoft] - Hackathon 2018
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning" />1<sup>st</sup>
              Place - Wiki and Technical Documentation Authoring Leader -
              [Microsoft] - 2017
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning" />
              Tech Transfer Awards for shipping new technology products -
              [Microsoft] - 2012
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning" />
              <a href="https://rany.tk/static/EPE.pdf" target="_blank">
                Upsilon Pi Epsilon [Honors]
              </a>{' '}
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={upelogo}
                alt=""
                height="10"
                width="16"
              />{' '}
              {'-'}
              <a
                style={{ fontSize: 15 }}
                href="http://upe.acm.org/"
                target="_blank"
              >
                [The Honor Society for The Computing and Information
                Disciplines]
              </a>
              {'- 2008'}
            </li>
            <img
              className="img-fluid img-profile mx-auto mb-2"
              src={epelogo}
              alt=""
              height="50"
              width="50"
            />
            <img src={epsilon} alt="" height="200" width="200" />
            <li>
              <i className="fa-li fa fa-trophy text-warning" />
              <a
                href="https://rany.tk/static/goldstar-903d5dc81f6e748e390869ecf2a99eac.png"
                target="_blank"
              >
                The Gold Star Award
              </a>{' '}
              <img
                className="img-fluid img-profile mx-auto mb-2"
                src={goldstarlogo}
                alt=""
                height="30"
                width="30"
              />{' '}
              from Microsoft VP for high impact - [Microsoft] - 2005
            </li>
            <img src={goldstar} alt="" height="300" width="300" />
          </ul>
        </div>
      </section>
      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="publications"
      >
        <div className="w-100">
          <h2 className="mb-5">
            <Link to="/publications">Publications</Link>
          </h2>
          <div className="social-icons">
            {config.publications.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url} target="_blank">
                  <i className={`fab ${icon}`} />
                </a>
              );
            })}
          </div>
          <p />
          <p />
          <ul></ul>

          <ul>
            <li>
              <a
                target="blank"
                href="https://www.linkedin.com/in/ranyelhousieny/detail/recent-activity/posts/"
              >
                Linkedin Publications
              </a>
            </li>
            <p></p>
            <li>
              <a target="blank" href="https://ranyel.medium.com/">
                Medium.com Publications
              </a>
              <p />
            </li>
            <li>
              <a
                target="blank"
                href="https://www.youtube.com/channel/UCJ6bFYSkBws-ug6fqJ_9CNg"
              >
                YouTube video Publications
              </a>
            </li>
            <p></p>
            <p></p>
            <li>
              <Link to="/microfrontends">Micro-Frontends</Link>
            </li>
            <p></p>
            <p></p>
            <li>
              <a
                target="blank"
                href="https://rany.tk/static/MonlithToMicroservices.pdf"
              >
                Moving from Monolith to Microservices Using AppSync and
                Amplify(Company project)
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
